var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"0RDnFpj3rDmCtnxthIzyW"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
import { BrowserTracing } from "@sentry/browser"


const attributes = [ 'data-testid', 'aria-label' ]

const findAttribute = (node) => {
  let result

  attributes.some(function(attribute) {
    const value = node.getAttribute(attribute)

    if (!value) {
      return false
    }

    result = `[${attribute}]: ${value}`

    return true
  })

  return result
}

const improveMessage = (node) => {
  let currentNode = node
  let maxDepth = 5
  let depth = 0
  let result

  while (!result && currentNode && depth < maxDepth) {
    const attribute = findAttribute(currentNode)

    if (attribute) {
      result = attribute
    }

    depth++
    currentNode = currentNode.parentNode
  }

  return result || node.className
}

const ignoreErrors = [
  /Please enable Blind signing or Contract data in the Ethereum app Settings/,
  /Must be handling a user gesture to show a permission request/,
  /encountered an error while attempting to update latest block/,
  /Please enable Contract data on the Ethereum app Settings/,
  /Cannot read properties of undefined (reading 'apply')/,
  /insufficient funds for intrinsic transaction cost/,
  /Text content does not match server-rendered HTML/,
  /NetworkError when attempting to fetch resource/,
  /Transaction reverted without a reason string/,
  /Response has no error or result for request/,
  /missing revert data in call exception/,
  /There was an error while hydrating/,
  /User denied transaction signature/,
  /User rejected the transaction/,
  /User denied message signature/,
  /The gas fee has been updated/,
  /Firma transazione annullata/,
  /TransactionRefusedOnDevice/,
  /Sign transaction cancelled/,
  /underlying network changed/,
  /promise rejection captured/,
  /User rejected the request/,
  /Action cancelled by user/,
  /Transaction was rejected/,
  /Actionscancelled by user/,
  /could not detect network/,
  /Internal JSON-RPC error/,
  /bad result from backend/,
  /Network request failed/,
  /call revert exception/,
  /Minified React error/,
  /database unavailable/,
  /Non-200 status code/,
  /Reject by the user/,
  /header not found/,
  /Hydration failed/,
  /missing response/,
  /rejected request/,
  /Unexpected token/,
  /Request aborted/,
  /Failed to fetch/,
  /Request failed/,
  /Network Error/,
  /User canceled/,
  /UNKNOWN_ERROR/,
  /bad response/,
  /timeout of/,
  /cancelled/,
  /Rejected/,
  /Unknow/,
]

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  normalizeDepth: 10,

  // We are losing some evens because of this. Temporarily shutting down
  // // https://docs.sentry.io/platforms/javascript/performance/
  integrations: [ new BrowserTracing() ],
  tracesSampleRate: 0.3,

  beforeSend: (event) => {
    const message = event?.extra?.error?.message || event?.extra?.message || event?.message

    const isIgnore = ignoreErrors.some((rule) => rule.test(message))

    if (isIgnore) {
      return null
    }

    return event
  },
  beforeBreadcrumb(breadcrumb, hint) {
    if ([ 'fetch', 'xhr' ].includes(breadcrumb.category)) {
      // We need to filter out useless data in breadcrumbs
      const isUseless = [ /fontawesome/, /infura/, /mixpanel/ ].some(
        (regx) => regx.test(breadcrumb.data.url)
      )

      if (isUseless) {
        return null
      }
    }

    if (breadcrumb.category === 'ui.click') {
      const { target } = hint.event

      if (target) {
        // By default, Sentry only shows the class of the clicked item. The class is not informative,
        // we will not be able to understand what kind of element it is. And this does not mean that the event handler
        // was on this element, it could have been at its parent or higher. Therefore, we go around his parents
        // and look for the attributes of 'data-testid' or 'aria-label'
        breadcrumb.message = improveMessage(target)
      }
    }

    if (breadcrumb.category === 'ui.input') {
      const { target } = hint.event

      if (target) {
        breadcrumb.message = target.value
      }
    }

    return breadcrumb
  },
})
